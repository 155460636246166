
var total = 0.00;
var maxcount = 0;
import { defineComponent } from "vue";
import {loadSStepSummary, sstepEarnings}  from "@/api/stairstep";

export default defineComponent({
  name: "stairstephistory", 
  methods: {
    async getThisMonthAndYear(){
      this.loading = true;
      var d = new Date();
      var m = d.getMonth();
      var y = d.getFullYear();
      this.month = m + 1;
      this.year = y;
      this.loading = false;
    },  
    async loadSStep_summary(){
      
      this.totalSstepComThisMonth = 0.00;
      console.log("this.totalSstepComThisMonth = " + this.totalSstepComThisMonth);
      this.loading = true;
      var params = {
        "month" : this.month,
        "year" : this.year
      }
      const response = await loadSStepSummary(params);
      this.list = response.data.pageList;
      maxcount = this.list.length;
      this.loading = false;
      
    },
    async getSstepEarnings(){
      this.loadingEarnings = true;
      const response = await sstepEarnings();
      this.totalSstepCom = response.data.totalSstepCom;
      this.totalPersonalPoints = response.data.totalPersonalPoints;
      this.totalGroupPoints = response.data.totalGroupPoints;
      this.loadingEarnings = false;
    },
    getTotalTEST(param1, param2){
      total = total + parseFloat(param1);
      console.log(total);
      console.log("maxcount - " + maxcount );
      console.log("param2 - " + param2 );
      if(param2 <= maxcount){
        this.totalSstepComThisMonth = total;
        total = 0.00;
        maxcount = 0;
      }
    },
    updateTotal(){
      this.totalSstepComThisMonth = total;
      console.log("total - " + total);
    }
    
  },
  mounted(){
      this.getThisMonthAndYear();
      this.loadSStep_summary();
      this.getSstepEarnings();
      
      
  },
  computed: {
  },
  data(){
      return {
        month:0,
        year: 0,
        list : [],
        loading: true,
        totalSstepCom: 0.00,
        totalPersonalPoints: 0.00,
        totalGroupPoints: 0.00,
        totalSstepComThisMonth: 0.00,
        loadingEarnings: true,
      }
  },
  components: {
  },
  props: {
    widgetClasses: String
  }
});

