
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import StairstepHistory from "@/components/stairstep/StairstepHistory.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "stairstep",
  components: {
    StairstepHistory,
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("stairstepTab") || "0");
      setCurrentPageTitle("Stairstep");

    });
    
  }
});
