import request from '@/utils/request'

//Stairstep Summary
export function loadSStepSummary(params) {
  return request({ url: 'vstairstep', method: 'post', data: params })
} 

//Stairstep Earnings
export function sstepEarnings() {
  return request({ url: 'vstairstepmodelmap', method: 'post', data: {} })
} 